import { useRef, useState } from 'react'
import { Button, Flex, Flexed, Input, Label, Scroll, Selectbox } from '../../Shared'
import { useEffect } from 'react'
import { foilcostlist, foldoneupcurve, foldtwoupcurve, oneupcurve, papers, platecost, diecutpresses, sara, twoupcurve} from './Calcs'
import Collapse from '../../Util/Collapse'
import { IoMdAdd } from 'react-icons/io'
import { FiMinusCircle } from "react-icons/fi";
import Modal from '../../modals/Modal'

import { v4 as uuidv4 } from 'uuid';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import Allbids from './Allbids'
import { FaList } from 'react-icons/fa'

const Pocketfolders = ({}) => {
   
const [showprocess, setShowprocess] = useState(false)
const [quantites, setQuantites] = useState([])

const [newinfo, setNewinfo] = useState([])

const [tempquan, setTempquan] = useState("")
const [foldertype, setFoldertype] = useState()
const [pocketinfo, setPocketinfo] = useState({press:"3",foldertype:"12",piecewidth:"9",piecelength:"12",pockets:"1",diecost:10,mr:"50"})

const [width, setWidth] = useState("")
const [leng, setLeng] = useState("")
const [squareinch, setSquareinch] = useState()
const [plateperm, setPlateperm] = useState(0)
const [plateslist, setPlateslist] = useState([])
const [tempplate, setTempplate] = useState({})
const [embossing, setEmbossing] = useState([])
const [diecutting, setDiecutting] = useState([])
const [folding, setFolding] = useState([])
const [process, setProcess] = useState({sides:1,mr:"50"})
const [tempemboss, setTempemboss] = useState([])
const [tempfoil, setTempfoil] = useState([])
const [processes, setProcesses] = useState([])
const [emboss, setEmboss] = useState([])
const [foiling, setFoiling] = useState([])
const [newprocess, setNewprocess] = useState([])
const [reset, setReset] = useState(true)
const [matpercent, setMatpercent] = useState(7)
const [laborpercent, setLaborpercent] = useState(10)
const quanbox = useRef()
const squareinchbox = useRef()
const squareinchbox2 = useRef()

const platetotal = useRef()
const foilcost = useRef()


 const y = ""




const checkquans = (e) => { 
    let h = [...quantites]
   

    if (e.key === "Enter") {
        let tempstring = e.target.value.replace(/\s/g, '');
        const separatedArray = [...tempstring.split(',')];
            separatedArray.map(item => {
                if(h.includes(item)){
return
                }else{
                   let newq = {
                    quantity:item,
                    emboss:emboss,
                    foiling:foiling,
                    diecutting:setdiecost(item),
                    folding:setfolding(item),
                   boxes:setboxes(item)

                   }
                   let gg = [...newprocess,newq]
                   gg.sort((a, b) => parseInt(a.quantity) - parseInt(b.quantity));
                
                  setNewprocess(gg)
                  quanbox.current.value=""  
                  let newsort = [...quantites,item]
                  newsort.sort((a, b) => parseInt(a) - parseInt(b));
                   setQuantites(newsort)

                }
            })
       
         
      }
}

  console.log(newprocess)

useEffect(() => {
    let r =[]
newprocess?.map(item => {
let h = {
   quantity: item.quantity,
   emboss:emboss,
   addedid:uuidv4(),
   foiling:foiling,
   diecutting:item.diecutting,
   folding:item.folding,
   boxes:item.boxes
}
r.push(h)
})
setNewprocess(r)
},[emboss,quantites,foiling,reset])










const processidtoname = (id) => {

    let y
    switch(id){
case "1":
     y = "Embossing";
    break;
    case "2":
    y = "Foiling";
    break;
    }
return y
}


const addplate = () => {

    if(process.width > "" && process.leng > "" && process.process > "" ){
        let h = {...process,process:process.process,processname:processidtoname(process.process),squareinch:process.squareinch,costofplate:process.costofplate,id:uuidv4(),backer:process.process==="1"? 58 : 0,name:process.name,foils:tempfoil}
    
       setPlateslist([...plateslist,h])
       setProcess({...process,squareinch:"",width:"",leng:"",backer:"",costofplate:"",id:""})
       setTempfoil([])
    }
    
}
console.log(process)

const addfoil = () => {
let y = {
    foilwidth:process.foilwidth,
    foillength:process.foillength,
    foilsquareinch:process.foilsquareinch,
    foilname:process.foilname,
    foilcost:process.foilcost,
    foilplate:process.name,
    id:uuidv4()
}
setProcess({...process,foilwidth:"",foillength:"",foilsquareinch:"",foilname:"",foilcost:"",id:""})
setTempfoil([...tempfoil,y])

}

console.log(tempfoil)
console.log(pocketinfo)
console.log(process)

useEffect(() => {
    let w = parseInt(process?.width) + 1 || 0
    let l = parseInt(process?.leng) + 1 || 0
    let squareinch = parseInt(w) * parseInt(l)

    let newprocess = process
    if(squareinch > 0){
    let plate = platecost.filter(filt => filt.squareinch === squareinch)
    newprocess.costofplate = plate[0]?.cost
    newprocess.squareinch = squareinch
   squareinchbox.current.value = squareinch
   platetotal.current.value = newprocess.costofplate
   setProcess(newprocess)
    }

    },[process?.width,process?.leng,process?.mr])

           

    useEffect(() => {
        let w = parseInt(process?.foilwidth) + 1 || 0
        let l = parseInt(process?.foillength) + 1 || 0
        let squareinch = parseInt(w) * parseInt(l)

        let newprocess = process
        if(parseInt(squareinch) > 0 && process.process ==="2"){
        let plate = foilcostlist.filter(filt => filt.squareinch === squareinch)
        newprocess.foilcost = plate[0]?.cost
        newprocess.foilsquareinch = squareinch
        squareinchbox2.current.value = squareinch
foilcost.current.value = newprocess.foilcost
       setProcess(newprocess)
        }
    
        },[process?.foilwidth,process?.foillength,process?.foilcost])


      
  
  const setboxes = (quan) => {
let a = parseInt(quan) / 1000

let b  = a * 5

return b
  }




   const saveprocess = () => {

    if(plateslist.length === 0){
return
    }
     let h   
        switch(parseInt(process.process)){
case 1:
    h = {
        mr:process.mr,
        press:pocketinfo.press,
        sides:process.sides,
        process:process.process,
        processname:processidtoname(process.process),
        plates:plateslist,
        nameofprocess:process.nameofprocess,
        id:uuidv4()
    }
    setEmboss([...emboss,h]);
    break;
    case 2:
         h = {
            mr:process.mr || 50,
            press:pocketinfo.press,
            sides:process.sides,
            process:process.process,
            processname:processidtoname(process.process),
            plates:plateslist,
            nameofprocess:process.nameofprocess,
            id:uuidv4(),
            foilwidth:process.foilwidth,
            foillength:process.foillength,
            foilperm:process.foilperm,
            foilsquareinch:process.foilsquareinch,
        }
   
setFoiling([...foiling,h]);
    break;
        }

 setPlateslist([])
 setProcess({...process,squareinch:"",width:"",leng:"",backer:"",costofplate:"",id:"",process:"",sides:"1",press:"",name:"",nameofprocess:""})
setReset(!reset)
   }


   console.log(emboss)

const removeplate = (plate) => {

let r =emboss
r.map(item => {
      let g =   item.plates.filter(filt => filt.id !== plate.id)
       // item.plates = r
       item.plates = g
    })
setEmboss(r)
setReset(!reset)
}

const removefoilplate = (plate) => {

let r =foiling
r.map(item => {
      let g =   item.plates.filter(filt => filt.id !== plate.id)
       // item.plates = r
       item.plates = g
    })
setFoiling(r)
setReset(!reset)
}

const removefoil = (plate,id) => {
let fg = newprocess
let yy
fg.map(item => {
    let gg = item.foiling.map(foi => {
        foi.plates.map(plate => {
          yy =  plate.foils.filter(filt => filt.id !== id)
plate.foils = yy
        })
    })
   
})
setProcess(fg)
setReset(!reset)
    }



const removequantity = (id) => {
  let h = newprocess.emboss?.filter(filt => filt.addedid !== id)

}

const removeprocess = (embo) => {
    let hh = emboss.filter(filt => filt.id !== embo.id)
setEmboss(hh)
setReset(!reset)
}

const removefoilprocess = (foil) => {
    let hh = foiling.filter(filt => filt.id !== foil.id)
setFoiling(hh)
setReset(!reset)
}



const getprocesstotal= (item,processitem) => {

let y = 0
let q


item[processitem]?.map(proc => {
    y += getperm(item.quantity,proc) * getpieces(item.quantity,proc) / 1000
    y += parseFloat(proc.mr)
    y +=parseFloat(gettotalpaltes(proc))

})
   
     
   return y
    // ((parseFloat(getperm(item.quantity,embos)) * (parseFloat(getpieces(item.quantity,embos)) / 1000) ) + parseFloat(embos.mr) + (parseFloat(gettotalpaltes(embos)))).toFixed(2)

}



const changepocketinfo = (e,item,value,index) => {

    let newarray = newprocess
    newarray.map(item => {
    let old =item
    item.diecutting.diecost = e.target.value
})
setNewprocess(newarray)
}


const savepocketfolder = () => {

let g = {
    item:"Pocket Folder",
   details:newprocess,
   folderinfo:pocketinfo,
   id:uuidv4()
}


//  setEstitems([...estitems, g])
  
}


console.log(newprocess)






  


console.log(pocketinfo)

   const setdiecost = (quan) => {
    let newquan = quan / 1000
    let newdiecutting = {}
    let x
    let folderpieces
    let newmr

              switch(pocketinfo.press){
    case '3':
         folderpieces = quan * 1
        x = oneupcurve.filter(filt => filt.start <= quan && filt.end >= quan)
        newmr = 50
       // z = foldoneupcurve.filter(filt => filt.start <= y.sheets && filt.end >= y.sheets)
        break;
        case '4':
         folderpieces = quan * 2
        x = twoupcurve.filter(filt => filt.start <= quan && filt.end >= quan)
        newmr = 55
       // z = foldoneupcurve.filter(filt => filt.start <= y.sheets && filt.end >= y.sheets)
        break;
          } 

          if(x?.length > 0){
            newdiecutting.oridquan = quan
            newdiecutting.cost = (x[0]?.price)
            newdiecutting.price = (x[0]?.price * parseFloat(newquan))
            newdiecutting.mr = newmr
            newdiecutting.pieces = folderpieces
            newdiecutting.press = x[0]?.name
            newdiecutting.pressid = x[0]?.id
           newdiecutting.pockets = pocketinfo.pockets
           newdiecutting.type="Pocket Folder"
            newdiecutting.diecost=pocketinfo.diecost
            // foldingcost = (z[0]?.price * (parseInt(cuttingpieces) /1000))
            // foldingprice = z[0]?.price
            // foldmr = z[0]?.mr
        }else{
            newdiecutting.oridquan = 0
            newdiecutting.cost = 0
            newdiecutting.price = 0
            newdiecutting.mr = 0
            newdiecutting.pieces = 0
            newdiecutting.press = ""
            newdiecutting.pressid = ""
          newdiecutting.pockets = ""
            newdiecutting.type=""
            newdiecutting.diecost=0
    }
    // let newq = [...diecutting,newdiecutting]
    // newq.sort((a, b) => parseInt(a.oridquan) - parseInt(b.oridquan));
    // setDiecutting(newq)
    return newdiecutting
    }

console.log(pocketinfo)

const setfolding = (quan) => {
    let newquan = quan / 1000
    let newfolding = {}
    let z
    let folderpieces
    let  newmr 
    let press
              switch(pocketinfo.pockets){
    case '1':
         folderpieces = quan * 1
         newmr = 50
         press = "1 Pocket"
       z = foldoneupcurve.filter(filt => filt.start <= quan && filt.end >= quan)
        break;
        case '2':
         folderpieces = quan * 2
        z = foldtwoupcurve.filter(filt => filt.start <= quan && filt.end >= quan)
        newmr = 55
        press = "2 Pocket"
        // z = foldoneupcurve.filter(filt => filt.start <= y.sheets && filt.end >= y.sheets)
        break;
        case '3':
            folderpieces = quan * 2
           z = foldtwoupcurve.filter(filt => filt.start <= quan && filt.end >= quan)
           newmr = 55
           press = "1 Pocket Hand Feed"
           // z = foldoneupcurve.filter(filt => filt.start <= y.sheets && filt.end >= y.sheets)
           break;
           case '4':
            folderpieces = quan * 2
           z = foldtwoupcurve.filter(filt => filt.start <= quan && filt.end >= quan)
           newmr = 55
           press = "2 Pocket Hand Feed"
           // z = foldoneupcurve.filter(filt => filt.start <= y.sheets && filt.end >= y.sheets)
           break;
        default: 
    
        break;
          } 
    
          if(z?.length > 0){
            newfolding.origquan = quan
            newfolding.cost = (z[0]?.price)
            newfolding.price = (z[0]?.price * parseFloat(newquan))
            newfolding.mr = newmr
            newfolding.pieces = folderpieces
            newfolding.press = press
            newfolding.pressid = 
           newfolding.pockets = pocketinfo.pockets
           newfolding.type="Pocket Folder"
            newfolding.diecost=pocketinfo.diecost
        }else{
            newfolding.origquan = 0
            newfolding.cost = 0
            newfolding.price = 0
            newfolding.mr = 0
            newfolding.pieces = 0
            newfolding.press =""
            newfolding.pressid = ""
           newfolding.pockets = ""
           newfolding.type=""
           newfolding.diecost=0
    }
//     let newq = [...folding,newfolding]
//     newq.sort((a, b) => parseInt(a.origquan) - parseInt(b.origquan));
//   setFolding(newq)
return newfolding
    }


const getmaterials = (item) => {
  let yy =item
let r = 0
let plates = 0
let foils = 0
r += item.diecutting?.diecost

item.emboss?.map(em => {
    em.plates.map(plate => {
        plates += plate.backer
        plates += plate.costofplate
    })
    })
    item.foiling?.map(em => {
        em?.plates.map(plate => {
            plates += parseFloat(plate.backer)
            plates += parseFloat(plate.costofplate)
plate.foils.map(fo => {
    foils += parseFloat(fo.foilcost)
})
        })
        })
r += plates
r += foils
item.mats = r
item.matpercent = matpercent
return r
}


const getlabor = (item) => {
    console.log(item)
let r = 0
let emb = 0

r += item.diecutting?.price
r += item.diecutting?.mr
r += item.folding?.price
r += item.folding?.mr
item.emboss?.map(em => {
    emb += (getperm(item.quantity,em) * (getpieces(item.quantity,em) / 1000) ) + parseFloat(em.mr)  
    })
    item.foiling?.map(em => {
        emb += (getperm(item.quantity,em) * (getpieces(item.quantity,em) / 1000) ) + parseFloat(em.mr)  
        })

r += emb
item.labor = r
item.laborpercent = laborpercent
return r
}




    const getpieces = (quantity,item) => {

        let a
       switch(item.press){
case "3":
a = parseInt(quantity) * 1;
break;
       }
  return a     
    }  

    const getperm = (quantity,item) => {
        let a
       switch(item.plates[0]?.press){
case "13":
let g = sara.filter(filt => filt.start <= quantity && filt.end >= quantity)
a = g[0]?.price
break;
       }
  return a     
    } 
      

    const gettotalpaltes = (item) => {
let y = 0
item.plates?.map(plate => {
 let hh = parseInt(plate.costofplate) + parseInt(plate.backer)
 y += hh
 if(plate.foils.length>0){
plate.foils.map(foi => {
y += foi.foilcost
})
 }

})
return y
    }




  

console.log(folding)



  return (
    <div >
          <Modal show={showprocess} headerbgcolor="#8498AC" width="75%" headercolor="#144259" header={`Add Process`} onCancel={() => {setShowprocess(false)}} ></Modal>
         
   <Flexed>Standard P/F
    <Flex>her</Flex>
   </Flexed>
<Flexed margin="20px 0px 0px 0px">
<Flex>
        <Flexed>
            <Flex flex=".5" >Parent W:<br /><Input onChange={e => setPocketinfo({...pocketinfo,parentwidth:e.target.value})}/></Flex>
            <Flex flex=".5">Parent L:<br /><Input onChange={e => setPocketinfo({...pocketinfo,parentlength:e.target.value})}/></Flex>
        </Flexed>
    </Flex>
    <Flex>presses
        <Flexed>
            <Flex flex=".5" >Piece W:<br /><Input onChange={e => setPocketinfo({...pocketinfo,piecewidth:e.target.value})} value={pocketinfo.piecewidth}/></Flex>
            <Flex flex=".5">Piece L:<br /><Input onChange={e => setPocketinfo({...pocketinfo,piecelength:e.target.value})} value={pocketinfo.piecelength}/></Flex>
        </Flexed>
        
    </Flex>
    <Flex flex=".5" style={{marginTop:"-10px"}}>Die Cost:<br /><Input onChange={e => changepocketinfo(e,"","","")} value={pocketinfo.diecost}/></Flex>
    <Flex style={{marginTop:"-10px"}}><Label>Die Cut Machine</Label><br />
    <Selectbox onChange={e => setPocketinfo({...pocketinfo,press:e.target.value})} value={pocketinfo?.press}>
        <option value="" selected disabled>Machine</option>
        {
            diecutpresses.map(press => {
              return <option key={press.id} value={press?.id}>{press?.name}</option>
            })
        }
        </Selectbox>
    </Flex>
    <Flex  style={{marginTop:"-10px"}}>Fold & Glue<br />
                <Selectbox onChange={e => setPocketinfo({...pocketinfo,pockets:e.target.value})}>
                    <option value="1 Pocket">1 Pocket</option>
                    <option value="2">2 Pocket</option>
                    <option value="3">1 Pocket Hand Feed</option>
                    <option value="4">2 Pocket Hand Feed</option>
                </Selectbox>
               </Flex>
</Flexed>
<Flexed>
      <Flex flex=".2" display="flex"  padding="12px 0px 0px 0px">Quantity: <br /> <Input maxWidth="150px" type="text" width="75%"  ref={quanbox} onKeyDown={e => checkquans(e)} /></Flex>
      {quantites?.map(quan => {
                return <Flex flex=".2" display="flex" alignItems="center" padding="18px 0px 0px 0px"><br /><FiMinusCircle />&nbsp;<Input type="text" maxWidth="150px" width="75%" value={quan} disabled/></Flex>
               })
               }
      </Flexed>
<Flexed margin="20px 0px 0px 0px" alignItems="flex-start">
        <Flex>Process<br />
        <Selectbox onChange={e => setProcess({...process,process:e.target.value})} value={process?.process}>
        <option value="">Select Process</option>
            <option value="1">Embossing</option>
            <option value="2">Foiling</option>
            <option value="3">Perf</option>
            <option value="4">Score</option>
        </Selectbox>
        </Flex>

        <Flex>Process Name <Input type="text" onChange={e => setProcess({...process,nameofprocess:e.target.value})} value={process?.nameofprocess}/></Flex>
        <Flex flex=".5"><Label>Side(s)</Label><br />
    <Selectbox >
        <option value="1">1</option>
        <option value="2">2</option>
        </Selectbox>   <Flex flex="0" textAlign="right" >
            
      </Flex> 
    </Flex>
        <Flex flex=".5">M/R <Input type="text" value={process.mr} onChange={e => setProcess({...pocketinfo,mr:e.target.value})}/>
</Flex>
<Flex><Label>Machine</Label><br />
    <Selectbox value={process.press} onChange={e => setProcess({...process,press:e.target.value})}>
        <option value="" selected disabled>Machine</option>
        {
            diecutpresses.map(press => {
              return <option key={press.id} value={press.id}>{press.name}</option>
            })
        }
        </Selectbox><br /><br />   <Flex flex="0" textAlign="right" >
            
      </Flex> 
    </Flex>
       
    </Flexed>

   

<Flexed>
<Flex>Plate Name <Input type="text" onChange={e => setProcess({...process,name:e.target.value})} value={process?.name}/></Flex>
<Flex flex=".5">Width <Input type="text" onChange={e => setProcess({...process,width:e.target.value})} value={process?.width}/></Flex>
        <Flex flex=".5">Length <Input type="text" onChange={e => setProcess({...process,leng:e.target.value})} value={process?.leng}/></Flex>
        <Flex flex=".5">Square Inches <Input type="text" ref={squareinchbox} value={process?.squareinch} />{squareinch > 0 && <div style={{fontSize:"calc(.6rem + .2vw)"}} >1" auto added to W & L</div>}</Flex>
        <Flex flex=".5"><Label>Cost</Label><Input type="text" onChange={e => setProcess({...process,total:e.target.value})} ref={platetotal} value={process?.costofplate}/></Flex>
        <Flex flex="0" textAlign="right" style={{marginLeft:"40px"}}></Flex> 
</Flexed>
   
    {
        process?.process === "2" && <><Flexed>
        <Flex flex=".5" textAlign="right" display="flex" alignItems="center" >Foil Info:</Flex>
        <Flex flex="1.5">Foil Name <Input type="text" value={process?.foilname} onChange={e => setProcess({...process,foilname:e.target.value})}/></Flex>
        <Flex >Width <Input type="text" value={process?.foilwidth} onChange={e => setProcess({...process,foilwidth:e.target.value})}/></Flex>
        <Flex >Length <Input type="text" value={process?.foillength} onChange={e => setProcess({...process,foillength:e.target.value})}/></Flex>
        <Flex>Square Inches <Input type="text" ref={squareinchbox2} value={process.foilsquareinch} /></Flex>
        <Flex >Cost <Input type="text" ref={foilcost} value={process?.foilcost} onChange={e => setProcess({...process,foilcost:e.target.value})}/></Flex>
        <Flex display="flex" alignItems="center"><Button onClick={e => addfoil()} disabled={process.squareinch > 0 ? false : true}>Add Foil</Button></Flex>
    </Flexed>
    </>
    }
<Flexed padding="10px 0px">
<Flex textAlign="right"><Button style={{marginRight:"30px"}} onClick={e => addplate()} >Add Plate</Button></Flex>
<Flex flex="0"> <Button onClick={e => saveprocess(e)} disabled={process.process !== "" && process.press !== "" && process.process !==undefined && process.press !==undefined ? false : true}>Save Process</Button></Flex>
</Flexed>
    <Flexed alignItems="flex-start" margin="20px 0px 0px 0px" gap="30px">
<Flex flex="1" >
    {
        plateslist.length > 0 && <>
              Plates
      <Flexed margin="0px 0px 5px 0px" borderBottom="1px solid black" >
      <Flex  flex="1.3">Type</Flex>
      <Flex  flex="1.3">Name</Flex>
        <Flex  flex="1.4">Size</Flex>
        <Flex >Cost</Flex>
        <Flex >Counter</Flex>
        <Flex >Total</Flex>
        <Flex  flex=".5">Del</Flex>
      </Flexed>
      {
        plateslist?.map((item,index) => {
         return <Flexed margin="0px 0px 5px 0px">
          <Flex  flex="1.3">{item.processname}</Flex>
          <Flex  flex="1.3">{item.name}</Flex>
          <Flex  flex="1.4">{item.width} X {item.leng} = {item.squareinch}"</Flex>
          <Flex  >{item.costofplate.toFixed(2)}</Flex>
          <Flex  >{item.backer>0?item.backer.toFixed(2) : ""}</Flex>
          <Flex  >{(item.costofplate + item.backer).toFixed(2)}</Flex>
          <Flex   flex=".5"></Flex>
        </Flexed>
        })
      }
    {
          plateslist?.length > 0 ? <> <Flexed style={{borderTop:"1px solid black"}} margin="0px 0px 5px 0px">
          <Flex  flex="1.3">&nbsp;</Flex>
          <Flex   flex="1.4">&nbsp;</Flex>
          <Flex ></Flex>
          <Flex ></Flex>
          <Flex ></Flex>
          <Flex  flex=".5"></Flex>
        </Flexed></> : null
        }
        </>
    }
    </Flex>
    <Flex>
    {
        tempfoil.length  > 0 && <>
              Foil
      <Flexed margin="0px 0px 5px 0px" borderBottom="1px solid black" >
      <Flex  flex="1.3">Plate</Flex>
      <Flex  flex="1.3">Name</Flex>
        <Flex  flex="1.4">Size</Flex>
        <Flex  flex="1.4">Sq In</Flex>
        <Flex >Cost</Flex>
        <Flex  flex=".5"></Flex>
      </Flexed>
      {
        tempfoil?.map((item,index) => {
            return <Flexed margin="0px 0px 5px 0px">
            <Flex  flex="1.3">{item.foilplate}</Flex>   
            <Flex  flex="1.3">{item.foilname}</Flex>
            <Flex  flex="1.3">{item.foilwidth} X {item.foillength}</Flex>
            <Flex  flex="1.4">{item.foilsquareinch}"</Flex>
            <Flex  >{parseFloat(item.foilcost)?.toFixed(2)}</Flex>
            <Flex   flex=".5"></Flex>
          </Flexed>
        })
      }
       </>
    }
    {
        plateslist[0]?.foils.length > 0 ?
        <>Foils
        <Flexed margin="0px 0px 5px 0px" borderBottom="1px solid black">
            <Flex  flex="1.3">Plate</Flex>   
            <Flex  flex="1.3">Name</Flex>
            <Flex  flex="1.3">Size</Flex>
            <Flex  flex="1.4">Sq In"</Flex>
            <Flex  >Cost</Flex>
            <Flex   flex=".5"></Flex>
          </Flexed>
          {plateslist.map(plate => {
          return plate.foils?.map((item,index) => {
          return <Flexed margin="0px 0px 5px 0px">
          <Flex  flex="1.3">{item?.foilplate}</Flex>   
          <Flex  flex="1.3">{item?.foilname}</Flex>
          <Flex  flex="1.3">{item?.foilwidth} X {item?.foillength}</Flex>
          <Flex  flex="1.4">{item?.foilsquareinch}"</Flex>
          <Flex  >{parseFloat(item?.foilcost)?.toFixed(2)}</Flex>
          <Flex   flex=".5"></Flex>
        </Flexed>
      })  
        })
    }
        </>
        : null  
        }
    {
        plateslist.foils?.length> 0 ?<>
          <Flexed margin="0px 0px 5px 0px" borderBottom="1px solid black" >
      <Flex  flex="1.3">Plate</Flex>
      <Flex  flex="1.3">Name</Flex>
        <Flex  flex="1.4">Size</Flex>
        <Flex  flex="1.4">Sq In</Flex>
        <Flex >Cost</Flex>
        <Flex  flex=".5"></Flex>
      </Flexed>
        </> : null
    }
    </Flex>
</Flexed>
<Flexed alignItems="flex-start" gap="30px">
    <Flex>
        <Flexed>
        <Flex border="1px solid #4EC0AC" padding="5px" >
  <Scroll maxHeight="300px">
  <p>Die Cutting</p> 
{
     diecutting && <>
       <Flexed >
     
        <Flex borderBottom="1px solid black" flex="1.5">Press</Flex>
        <Flex borderBottom="1px solid black">Pieces</Flex>
        <Flex borderBottom="1px solid black">Pockets</Flex>
        <Flex borderBottom="1px solid black">M/R</Flex>
        <Flex borderBottom="1px solid black">Die</Flex>
        <Flex borderBottom="1px solid black">/M</Flex>
        <Flex borderBottom="1px solid black">Price</Flex>
        <Flex borderBottom="1px solid black">Total</Flex>
        <Flex  flex="0" display="flex" alignItems="center" justifyContent="flex-end" ><FiMinusCircle style={{opacity:"0"}}/></Flex>     
        </Flexed>  
        {
            newprocess?.map(die => {
               return <Flexed >
                   <Flex  flex="1.5">{die.diecutting?.press}</Flex>
                   <Flex >{die.diecutting?.pieces}</Flex>
                   <Flex >{die.diecutting?.pockets}</Flex>
                   <Flex >{die.diecutting?.mr}</Flex>
                   <Flex >{die.diecutting?.diecost}</Flex>
                   <Flex >{die.diecutting?.cost}</Flex>
                   <Flex >{die.diecutting?.price}</Flex>
                   <Flex >{(parseFloat(die.diecutting?.price) + parseFloat(pocketinfo?.diecost) + parseFloat(die.diecutting?.mr))}</Flex>
                   <Flex  flex="0" display="flex" alignItems="center" justifyContent="flex-end" ><FiMinusCircle style={{opacity:"0"}}/></Flex>     
                   </Flexed>
            })
        }
         
        </>
}
        </Scroll>
  </Flex>
        </Flexed>
    </Flex>
    <Flex>
    <Flexed>
        <Flex border="1px solid #4EC0AC" padding="5px" >
  <Scroll maxHeight="300px">
  <p>Folding & Glue</p> 
{
     newprocess && <>
       <Flexed >
        <Flex borderBottom="1px solid black" flex="1.5">Press</Flex>
        <Flex borderBottom="1px solid black">Pieces</Flex>
        <Flex borderBottom="1px solid black">M/R</Flex>
        <Flex borderBottom="1px solid black">/M</Flex>
        <Flex borderBottom="1px solid black">Price</Flex>
        <Flex borderBottom="1px solid black">Total</Flex>
        <Flex  flex="0" display="flex" alignItems="center" justifyContent="flex-end" ><FiMinusCircle style={{opacity:"0"}}/></Flex>     
        </Flexed>  
        {
            newprocess?.map(fold => {
               return <Flexed >
                   <Flex  flex="1.5">{fold.folding?.press}</Flex>
                   <Flex >{fold.folding?.pieces}</Flex>
                   <Flex >{fold.folding?.mr}</Flex>
                   <Flex >{fold.folding?.cost}</Flex>
                   <Flex >{fold.folding?.price}</Flex>
                   <Flex >{(parseFloat(fold.folding?.price) + parseFloat(fold.folding?.mr))}</Flex>
                   <Flex  flex="0" display="flex" alignItems="center" justifyContent="flex-end" ><FiMinusCircle style={{opacity:"0"}}/></Flex>     
                   </Flexed>
            })
        }
         
        </>
}
        </Scroll>
  </Flex>
        </Flexed>
    </Flex>
</Flexed>


<Flexed gap="30px" alignItems="flex-start" >
  <Flex border="1px solid #4EC0AC" padding="5px" >
  <Scroll maxHeight="300px">
  <p>Embossing</p>
  
{
     emboss.length > 0 && newprocess?.map(item => {
       return <>
       <div style={{borderBottom:"1px solid black",padding:"10px 0px 2px 0px"}}>
       <Flexed >
        <Flex borderBottom="1px solid black" fontWeight="bold">{item.quantity}</Flex>
     <Flex flex="1.5" borderBottom="1px solid black">Name</Flex>
        <Flex borderBottom="1px solid black">Pieces</Flex>
        <Flex borderBottom="1px solid black">Plates</Flex>
        <Flex borderBottom="1px solid black">M/R</Flex>
        <Flex borderBottom="1px solid black">/M</Flex>
        <Flex borderBottom="1px solid black">Price</Flex>
       
        <Flex borderBottom="1px solid black">Total</Flex>
        <Flex  flex="0" display="flex" alignItems="center" justifyContent="flex-end" ><FiMinusCircle style={{opacity:"0",color:"rgb(255,0,0,.7)",fontSize:"calc(1.1rem + .2vw)"}}/></Flex>     
        </Flexed>  
       {item.emboss.map(embos => {
        return <><Collapse style={{margin:"0",}} title={<Flexed >
        <Flex><FaList /></Flex>
        <Flex flex="1.5">{embos.nameofprocess}</Flex>
        <Flex >{getpieces(item.quantity,embos)}</Flex>
        
        <Flex >{gettotalpaltes(embos).toFixed(2)}</Flex>
        <Flex >{parseInt(embos.mr).toFixed(2)}</Flex>
        <Flex >{getperm(item.quantity,embos)}</Flex>
        <Flex >{(getperm(item.quantity,embos) * (getpieces(item.quantity,embos) / 1000) ).toFixed(2)}</Flex>
   
        <Flex >{((parseFloat(getperm(item.quantity,embos)) * (parseFloat(getpieces(item.quantity,embos)) / 1000) ) + parseFloat(embos.mr) + (parseFloat(gettotalpaltes(embos)))).toFixed(2)}</Flex>
        <Flex  flex="0" display="flex" alignItems="center" justifyContent="flex-end" ><FiMinusCircle style={{color:"rgb(255,0,0,.7)",fontSize:"calc(1.1rem + .2vw)",zIndex:"10"}} onClick={e => removeprocess(embos)}/></Flex>   
        </Flexed>}>
       <div style={{border:"1px solid #0E425A",marginBottom:"10px"}}>
        <Flexed >
        <Flex borderBottom="1px solid black">Plate</Flex>
        <Flex borderBottom="1px solid black">Size</Flex>
        <Flex borderBottom="1px solid black" >Sq In</Flex>
        <Flex borderBottom="1px solid black">Backer</Flex>
        <Flex borderBottom="1px solid black">Cost</Flex>
        <Flex >&nbsp;</Flex>
        <Flex borderBottom="1px solid black">Total</Flex>    
        <Flex borderBottom="1px solid black">&nbsp;</Flex>   
        </Flexed>  

        {
            embos.plates?.map(plate => {
                return  <Flexed >
                <Flex >{plate.name}</Flex>
                <Flex >{plate.width} X {plate.leng}</Flex>
                <Flex >{plate.squareinch}"</Flex>
                <Flex >{plate.backer}</Flex>
                <Flex >{plate.costofplate}</Flex>
                <Flex >&nbsp;</Flex>
                <Flex >{(parseFloat(plate.costofplate) + parseFloat(plate.backer)).toFixed(2)}</Flex>
                <Flex padding="0px 10px 0px 0px" display="flex" alignItems="center" justifyContent="flex-end" ><FiMinusCircle style={{color:"rgb(255,0,0,.7)",fontSize:"calc(1.1rem + .2vw)",zIndex:"100"}} onClick={e => removeplate(plate)}/></Flex>   
                </Flexed>  
            })
        }
</div>
        </Collapse>  
  
         </>
       })}
       </div> 
       {
         emboss.length > 1 &&  <Flexed>
         <Flex flex="7" >&nbsp;</Flex>
         <Flex >Total: </Flex>
         <Flex >{getprocesstotal(item,'emboss')?.toFixed(2)}</Flex>
         <Flex  flex="0" display="flex" alignItems="center" justifyContent="flex-end" ><FiMinusCircle style={{opacity:"0",color:"rgb(255,0,0,.7)",fontSize:"calc(1.1rem + .2vw)",zIndex:"100"}}/></Flex>     
 </Flexed>
       }
      
        </>
    })
}

        </Scroll>
  </Flex>

  <Flex border="1px solid #4EC0AC" padding="5px">
   <Scroll maxHeight="300px">
  <p>Foiling</p>
  
{
     foiling.length > 0 && newprocess?.map(item => {
       return <>
       <div style={{borderBottom:"1px solid black",padding:"10px 0px 2px 0px"}}>
       <Flexed >
        <Flex borderBottom="1px solid black" fontWeight="bold">{item.quantity}</Flex>
     <Flex flex="1.5" borderBottom="1px solid black">Name</Flex>
        <Flex borderBottom="1px solid black">Pieces</Flex>
        <Flex borderBottom="1px solid black">Plates</Flex>
        <Flex borderBottom="1px solid black">M/R</Flex>
        <Flex borderBottom="1px solid black">/M</Flex>
        <Flex borderBottom="1px solid black">Price</Flex>
       
        <Flex borderBottom="1px solid black">Total</Flex>
        <Flex  flex="0" display="flex" alignItems="center" justifyContent="flex-end" ><FiMinusCircle style={{opacity:"0",color:"rgb(255,0,0,.7)",fontSize:"calc(1.1rem + .2vw)"}}/></Flex>     
        </Flexed>  
       {item.foiling?.map(foil => {
        return <><Collapse style={{margin:"0",}} title={<Flexed >
        <Flex><FaList /></Flex>
        <Flex flex="1.5">{foil.nameofprocess}</Flex>
        <Flex >{getpieces(item.quantity,foil)}</Flex>
        
        <Flex >{gettotalpaltes(foil).toFixed(2)}</Flex>
        <Flex >{parseInt(foil.mr).toFixed(2)}</Flex>
        <Flex >{getperm(item.quantity,foil)}</Flex>
        <Flex >{(getperm(item.quantity,foil) * (getpieces(item.quantity,foil) / 1000) ).toFixed(2)}</Flex>
   
        <Flex >{((parseFloat(getperm(item.quantity,foil)) * (parseFloat(getpieces(item.quantity,foil)) / 1000) ) + parseFloat(foil.mr) + (parseFloat(gettotalpaltes(foil)))).toFixed(2)}</Flex>
        <Flex  flex="0" display="flex" alignItems="center" justifyContent="flex-end" ><FiMinusCircle style={{color:"rgb(255,0,0,.7)",fontSize:"calc(1.1rem + .2vw)",zIndex:"10"}} onClick={e => removefoilprocess(foil)}/></Flex>   
        </Flexed>}>
       <div style={{border:"1px solid #0E425A",marginBottom:"3px"}}>
        <Flexed  margin="0px 0px 3px 0px">
        <Flex borderBottom="1px solid black">Plate</Flex>
        <Flex borderBottom="1px solid black">Size</Flex>
        <Flex borderBottom="1px solid black" >Sq In</Flex>
    
        <Flex borderBottom="1px solid black">Cost</Flex>
        <Flex >&nbsp;</Flex>
        <Flex borderBottom="1px solid black">Total</Flex>    
        <Flex borderBottom="1px solid black">&nbsp;</Flex>   
        </Flexed>  

        {
            foil.plates?.map(plate => {
                return <><Flexed margin="0px 0px 3px 0px">
                <Flex >{plate.name}</Flex>
                <Flex >{plate.width} X {plate.leng}</Flex>
                <Flex >{plate.squareinch}"</Flex>
                <Flex >{plate.costofplate}</Flex>
                <Flex >&nbsp;</Flex>
                <Flex >{(parseFloat(plate.costofplate) + parseFloat(plate.backer)).toFixed(2)}</Flex>
                <Flex padding="0px 10px 0px 0px" display="flex" alignItems="center" justifyContent="flex-end" ><FiMinusCircle style={{color:"rgb(255,0,0,.7)",fontSize:"calc(1.1rem + .2vw)"}} onClick={e => removefoilplate(plate)}/></Flex>   
                </Flexed> 
{
    plate.foils.length > 0 ?<> <div><u>Foil</u></div>{ plate.foils.map(foi => {
return <>
     <Flexed  margin="0px 0px 1px 0px">
     <Flex>{foi.foilplate}</Flex>
        <Flex>{foi.foilname}</Flex>
        <Flex>{foi.foilwidth} X {foi.foillength}</Flex>
        <Flex >{foi.foilsquareinch}</Flex>
   
        <Flex>{foi.foilcost}</Flex>
        <Flex></Flex>
        <Flex>{foi.foilcost}</Flex>  
        <Flex padding="0px 10px 0px 0px" display="flex" alignItems="center" justifyContent="flex-end" ><FiMinusCircle style={{color:"rgb(255,0,0,.7)",fontSize:"calc(1.1rem + .2vw)"}} onClick={e => removefoil(plate,foi.id)}/></Flex>  
     
        </Flexed>  
    
    </>
   
    }
)} <hr style={{border:"2px solid #4EC0AC"}} /> </>:null
}
                </>  
            })
        }
</div>
        </Collapse>  

         </>
       })}
       </div> 
       {
        foiling.length > 1 && <Flexed>
        <Flex flex="7" >&nbsp;</Flex>
        <Flex >Total: </Flex>
        <Flex >{getprocesstotal(item,'foiling').toFixed(2)}</Flex>
        <Flex  flex="0" display="flex" alignItems="center" justifyContent="flex-end" ><FiMinusCircle style={{opacity:"0",color:"rgb(255,0,0,.7)",fontSize:"calc(1.1rem + .2vw)"}}/></Flex>     
</Flexed>
     }
        </>
    })
}
        </Scroll>
  </Flex>
</Flexed>



<Flexed gap="30px" alignItems="flex-start">
<Flex>
    <Flexed  borderBottom="1px solid black">
        <Flex>  Boxes</Flex>
    </Flexed>

    <Flexed>
    <Flex>Quantity</Flex>
    <Flex>Pieces</Flex>
    <Flex>Cost</Flex>

    </Flexed>  
    {
        newprocess.map(item => {
          return  <Flexed>
            <Flex>{item.quantity}</Flex>
            <Flex></Flex>
            <Flex>{item.boxes}</Flex>
        
            </Flexed>  
        })
    }
    
    </Flex>

<Flex border="1px solid #4EC0AC" padding="5px" flex="2">

<p>Totals:</p>

<Flexed style={{borderBottom:"1px solid black",padding:"10px 0px 2px 0px"}}>
<Flex>Quantity</Flex>
<Flex flex=".3">&nbsp;</Flex>
<Flex>Mat Sub </Flex>
<Flex>Mat % </Flex>
<Flex>Mat Total </Flex>
<Flex flex=".3">&nbsp;</Flex>
<Flex>Labor Sub </Flex>
<Flex>Labor %</Flex>
<Flex>Labor Total </Flex>
<Flex flex=".3">&nbsp;</Flex>
<Flex>Total</Flex>
</Flexed>

{
newprocess.map(item => {
return <Flexed>
<Flex>{item.quantity}</Flex>
<Flex flex=".3">&nbsp;</Flex>
<Flex>{getmaterials(item).toFixed(2)}</Flex>
<Flex><Input width="50%" type="text" value={matpercent} onChange={e => setMatpercent(e.target.value)}/></Flex>
<Flex>{(((matpercent / 100) + 1) * getmaterials(item)).toFixed(2)}</Flex>
<Flex flex=".3">&nbsp;</Flex>
<Flex>{getlabor(item).toFixed(2)}</Flex>
<Flex><Input width="50%" type="text" value={laborpercent} onChange={e => setLaborpercent(e.target.value)}/></Flex>
<Flex>{(((laborpercent / 100) + 1) * getlabor(item)).toFixed(2)}</Flex>
<Flex flex=".3">&nbsp;</Flex>
<Flex>{((((matpercent / 100) + 1) * getmaterials(item)) + (((laborpercent / 100) + 1) * getlabor(item))).toFixed(2)}</Flex>
</Flexed>


}) 
}


</Flex>
</Flexed>

<Flexed>
    <Flex><Button onClick={e => savepocketfolder()} >Save Pocket Folder</Button></Flex>
</Flexed>
<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
   


    </div>
  )
}

export default Pocketfolders



