import React, { useContext, useState } from 'react'
import { Flex, Flexed, Input } from '../../Shared'
import { useEffect } from 'react'
import { getlabor, getmaterials } from './Processes'
import BookEstimate from '../../Context/BookEstimate'

const Allbids = ({estitems,savedbooks,selectedIndex,setSelectedIndex}) => {




const {items,setItems,currentbook,setCurrentbook,setBookinfo,getlabor,gettotlabor,getmat,gettotmat} = useContext(BookEstimate)


const setbookredo = (book) => {

    let g = savedbooks.filter(filt => filt.id === (book.id))
    console.log(g[0])
    
    setSelectedIndex(2)  
    setBookinfo(g[0])
    setItems(g[0].items) 
}




console.log(savedbooks)

  return (
    <>
   {estitems?.pocketfolder?.length > 0 &&
   <div>
  <Flexed borderBottom="1px solid black">
    
      </Flexed>
      </div>
    }
   {
    savedbooks.length > 0 && 
    <>
  <Flexed>
        Books:
    </Flexed>
    <Flexed>
       <Flex>Book</Flex>
       <Flex>Pages</Flex>
       <Flex flex="2">Text</Flex>
       <Flex flex="2">Covers</Flex>
       <Flex >Tabs</Flex>
       <Flex>Coiling</Flex>
       <Flex>Quantity</Flex>
       <Flex>Total</Flex>
   </Flexed>
    {
    savedbooks.map(book => {
       return  <div><Flexed fontSize="10pt">
       <Flex className="hand" onClick={e => setbookredo(book)}>{book?.bookname}</Flex>
       <Flex>{book?.totalpages}</Flex>
       <Flex flex="2">{book?.textname?.split('-')[0]}</Flex>
       <Flex flex="2">{book?.covername?.split('-')[0]}</Flex>
       <Flex>{book.items[0]?.tabs?.name?.split('-')[0]}</Flex>
       <Flex>{book.items[0]?.binding?.type}</Flex>
       <Flex></Flex>
       <Flex></Flex>
   </Flexed>
   {
    book.items.map(item =>{
        console.log(item)
      return  <Flexed fontSize="10pt">
        <Flex>&nbsp;</Flex>
        <Flex>&nbsp;</Flex>
        <Flex flex="2">&nbsp;</Flex>
        <Flex flex="2">&nbsp;</Flex>
        <Flex flex="2">&nbsp;</Flex>
        <Flex>&nbsp;</Flex>
        <Flex>{book.binding?.type}</Flex>
        <Flex>{item.quantity}</Flex>
        <Flex>{(gettotlabor(item,getlabor(item)) + gettotmat(item,getmat(item)))?.toFixed(2)}</Flex>
        {/* <Flex>{(((parseFloat(item?.laborpercent) + 1) * (parseFloat(item?.coiling?.coilingtotal) + parseFloat(item?.punch?.text?.punchtotal)  + parseFloat(item?.punch?.covers?.punchtotal + parseFloat(item?.punch?.other?.punchtotal) + parseFloat(item?.punch?.tabs?.punchtotal)) + parseFloat(50) + parseFloat(50))) + (parseFloat(item?.materialpercent) + 1) * (parseFloat(item?.coil.coiltotal) + parseFloat(item?.boxes.cost) + + parseFloat(item?.tabs.total))).toFixed(2)}</Flex> */}
    </Flexed>
    })
   }
  
   </div>
    })
   
}
    </>
   }   

  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
    
    </>
  )
}

export default Allbids