import styled from 'styled-components'
import LOGO from './Assets/CFPLOGO.png'
import { Img,Button } from './Shared';
import {Routes,Route,useNavigate } from 'react-router-dom'
import { useContext } from 'react'
//import Schedules from './Schedules/Schedules';
import UserContext from './Context/UserContext'
import Schedules from './Pages/Schedules/Schedule';

import Customers from './Pages/customer/Customers';
//import axios from 'axios'
//import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
//import Job from './Jobticket/Job';
import Jobs from './Pages/Jobs/Jobs'
import Newjob from './Pages/Jobs/Newjob';
import UpdateJob from './Pages/Jobs/Updatejob';
import Customer from './Pages/customer/Customer';
import NewCustomer from './Pages/customer/NewCustomer';
import EditCustomer from './Pages/customer/EditCustomer';
import Vendors from './Pages/vendors/Vendors'
import Vendor from './Pages/vendors/Vendor'
import Newvendor from './Pages/vendors/Newvendor'
import Pos from './Pages/po/Pos'
import Po from './Pages/po/Po';
import Newpo from './Pages/po/Newpo';
import Mainpage from './Mainpage';
import Quotes from './Pages/quotes/Quotes';
import NewQuote from './Pages/quotes/NewQuote';
import Shipping from './Pages/Jobs/Shipping';
import Employees from './Pages/employees/Employees';
import AddEmployee from './Pages/employees/AddEmployee';
import Employee from './Pages/employees/Employee';
import Estimate from './Pages/Est/Estimate';
import Books from './Pages/Est/Books';
import Upload from './Upload';

const Wrapper = styled.div`

position:relative;
width: 100%;
height:100vh;
overflow:hidden;
`;

const Header = styled.div`

width:calc(100% - (var(--AppGap) * 2));
position:fixed;
display:flex;
left:var(--AppGap);
height:50px;
background-color:white;

`;

const LogoArea = styled.div`

width:100%;
position:relative;

`;
const Notification = styled.div`

width:100%;
position:relative;
`;
const LogoutArea = styled.div`

width:100%;
position:relative;
display:flex;
align-items:flex-end;
justify-content:flex-end;

`;
const Navigation = styled.div`

width:100%;
position:fixed;
top:var(--headerHeight);
height:32px;
display:flex;
padding:0px 15px;
background:var(--plsGreen);
`;

const NavLink = styled.div`

padding:5px 15px;
position:relative;
color:white;
cursor:pointer;

 &:hover{

 }
`;
const Lower = styled.div`
width:100%;
position:relative;
top:calc(31px + var(--headerHeight));
display:flex;
justify-content:space-around;
margin-bottom:20px;

`;

const FastLinks = styled.div`

position:relative;
flex:.5;
max-width:100%;
background-color:#0E425A;
color:white;
max-width:230px;

`;

const Main = styled.div`

width:100%;
position:relative;
flex:2.5;
height:calc(100vh - var(--headerHeight) - 37px);
overflow-Y:scroll;
overflow-X:hidden;
padding:0px 10px 5px 5px;
white-space: pre-line;
word-wrap: break-word;
`;
const Dashboard = () => {
  
    const {userInfo,setUserinfo,close} = useContext(UserContext);


const nav = useNavigate();
  return (
<Wrapper>
   <Header >
    <LogoArea ><img src={LOGO} style={{height:"100%"}} /></LogoArea>
    <Notification ></Notification>
    <LogoutArea ><Button onClick={close} >{userInfo.newname}</Button></LogoutArea>
   </Header>
   <Navigation>
     <NavLink onClick={() => nav('')}>Dashboard</NavLink>
     <NavLink onClick={() => nav('quotes')}>Quotes</NavLink>
     <NavLink onClick={() => nav('jobs')}>Jobs</NavLink>
     <NavLink  onClick={() => nav('customers')}>Customers</NavLink>
     <NavLink  onClick={() => nav('vendors')}>Vendors</NavLink>
     <NavLink  onClick={() => nav('pos')}>P.O.'s</NavLink>
     <NavLink onClick={() => nav('employees')}>Employee's</NavLink>
     <NavLink onClick={() => nav('schedules')}>Schedule</NavLink>

    </Navigation>
    <Lower>
        <FastLinks></FastLinks>
        <Main>   
<Routes>
  <Route path="/jobs" element={<Jobs />}/>
  <Route path="/jobs/:id" element={<UpdateJob />}/>
    <Route path="/jobs/new" element={<Newjob />} />
   <Route path="/schedules" element={<Schedules />} />
   <Route path="/quotes" element={<Quotes />} />
   <Route path="/customers" element={<Customers />} />
   <Route path="/vendors" element={<Vendors />} />
   <Route path="/vendors/:id" element={<Vendor />} />
   <Route path="/vendors/new" element={<Newvendor />} />
   <Route path="/customers/:id" element={<Customer />} />
   <Route path="/customers/new" element={<NewCustomer />} />
   <Route path="/customers/edit/:id" element={<EditCustomer />} />
   <Route path="/pos" element={<Pos />} />
   <Route path="/po/:id" element={<Po />} />
   <Route path="/pos/new" element={<Newpo />} />
   <Route path="/quotes" element={<Quotes />} />
   <Route path="/newquote" element={<NewQuote />} />
   <Route path="/packingslip" element={<Shipping />} />
   <Route path="/employees" element={<Employees />} />
   <Route path="/employees/new" element={<AddEmployee />} />
   <Route path="/employees/employee/:id" element={<Employee />} />
   <Route path='*' element={<Mainpage />} />
   <Route path='/estimate' element={<Estimate />} />
   <Route path='/estimate/:id' element={<Estimate />} />
   <Route path='/upload' element={<Upload />} />
   

</Routes>

        </Main>

    </Lower>
    <br/>
    <br/>
</Wrapper>
  )
}

export default Dashboard