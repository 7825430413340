export const oneupcurve = [
    {
        start:1,
        end:499,
        price:52,
        mr:50,
        id:3,
        name:"S Miller"
    },
    {
        start:500,
        end:999,
        price:52,
        mr:50,
        id:3,
        name:"S Miller"
    },
    {
        start:1000,
        end:1999,
        price:57,
        mr:50,
        id:3,
        name:"S Miller"
    },
    {
        start:2000,
        end:2499,
        price:52,
        mr:50,
        id:3,
        name:"S Miller"
    },
    {
        start:2500,
        end:2999,
        price:47,
        mr:50,
        id:3,
        name:"S Miller"
    },
    {
        start:3000,
        end:3999,
        price:44,
        mr:50,
        id:3,
        name:"S Miller"
    },
    {
        start:4000,
        end:4999,
        price:42,
        mr:50,
        id:3,
        name:"S Miller"
    },
    {
        start:5000,
        end:5999,
        price:37,
        mr:50,
        id:3,
        name:"S Miller"
    },
    {
        start:6000,
        end:7499,
        price:35,
        mr:50,
        id:3,
        name:"S Miller"
    },
    {
        start:7500,
        end:9999,
        price:33,
        mr:50,
        id:3,
        name:"S Miller"
    },
    {
        start:10000,
        end:12499,
        price:31,
        mr:50,
        id:3,
        name:"S Miller"
    },
    {
        start:12500,
        end:14999,
        price:29,
        mr:50,
        id:3,
        name:"S Miller"
    },
    {
        start:15000,
        end:17499,
        price:28,
        mr:50,
        id:3,
        name:"S Miller"
    },
    {
        start:17500,
        end:19999,
        price:28,
        mr:50,
        id:3,
        name:"S Miller"
    },
    {
        start:20000,
        end:22499,
        price:27,
        mr:50,
        id:3,
        name:"S Miller"
    },
    {
        start:22500,
        end:24999,
        price:27,
        mr:50,
        id:3,
        name:"S Miller"
    },
    {
        start:25000,
        end:29999,
        price:26,
        mr:50,
        id:3,
        name:"S Miller"
    },
    {
        start:30000,
        end:39999,
        price:25,
        mr:50,
        id:3,
        name:"S Miller"
    },
    {
        start:40000,
        end:49999,
        price:24,
        mr:50,
        id:3,
        name:"S Miller"
    },
    {
        start:50000,
        end:59999,
        price:23,
        mr:50,
        id:3,
        name:"S Miller"
    },
    {
        start:60000,
        end:74999,
        price:23,
        mr:50,
        id:3,
        name:"S Miller"
    },
    {
        start:75000,
        end:99999,
        price:22,
        mr:50,
        id:3,
        name:"S Miller"
    },
    {
        start:100000,
        end:1000000000,
        price:21,
        mr:50,
        id:3,
        name:"S Miller"
    }
]

export const twoupcurve = [
    {
        start:1,
        end:499,
        price:61,
        mr:55,
        id:"4",
        name:"L Miller"

    },
    {
        start:500,
        end:999,
        price:61,
        mr:55,
        id:"4",
        name:"L Miller"

    },
    {
        start:1000,
        end:1999,
        price:110,
        mr:55,
        id:"4",
        name:"L Miller"

    },
    {
        start:2000,
        end:2499,
        price:71,
        mr:55,
        id:"4",
        name:"L Miller"

    },
    {
        start:2500,
        end:2999,
        price:61,
        mr:55,
        id:"4",
        name:"L Miller"

    },
    {
        start:3000,
        end:3999,
        price:56,
        mr:55,
        id:"4",
        name:"L Miller"

    },
    {
        start:4000,
        end:4999,
        price:51,
        mr:55,
        id:"4",
        name:"L Miller"

    },
    {
        start:5000,
        end:5999,
        price:46,
        mr:55,
        id:"4",
        name:"L Miller"

    },
    {
        start:6000,
        end:7499,
        price:45,
        mr:55,
        id:"4",
        name:"L Miller"

    },
    {
        start:7500,
        end:9999,
        price:43,
        mr:55,
        id:"4",
        name:"L Miller"

    },
    {
        start:10000,
        end:12499,
        price:41,
        mr:55,
        id:"4",
        name:"L Miller"

    },
    {
        start:12500,
        end:14999,
        price:40,
        mr:55,
        id:"4",
        name:"L Miller"

    },
    {
        start:15000,
        end:17499,
        price:39,
        mr:55,
        id:"4",
        name:"L Miller"

    },
    {
        start:17500,
        end:19999,
        price:38,
        mr:55,
        id:"4",
        name:"L Miller"

    },
    {
        start:20000,
        end:22499,
        price:37,
        mr:55,
        id:"4",
        name:"L Miller"

    },
    {
        start:22500,
        end:24999,
        price:37,
        mr:55,
        id:"4",
        name:"L Miller"

    },
    {
        start:25000,
        end:29999,
        price:36,
        mr:55,
        id:"4",
        name:"L Miller"

    },
    {
        start:30000,
        end:39999,
        price:36,
        mr:55,
        id:"4",
        name:"L Miller"

    },
    {
        start:40000,
        end:49999,
        price:35,
        mr:55,
        id:"4"
    },
    {
        start:50000,
        end:59999,
        price:34,
        mr:55,
        id:"4",
        name:"L Miller"

    },
    {
        start:60000,
        end:74999,
        price:34,
        mr:55,
        id:"4",
        name:"L Miller"

    },
    {
        start:75000,
        end:99999,
        price:34,
        mr:55,
        id:"4",
        name:"L Miller"

    },
    {
        start:100000,
        end:1000000000,
        price:33,
        mr:55,
        id:"4",
        name:"L Miller"

    }
]


export const skluge = [
    {
        start:1,
        end:499,
        price:61,
        mr:50,
        id:"21",
        name:"S Kluge"

    },
    {
        start:500,
        end:999,
        price:61,
        mr:50,
        id:"21",
        name:"S Kluge"

    },
    {
        start:1000,
        end:1999,
        price:110,
        mr:50,
        id:"21",
        name:"S Kluge"

    },
    {
        start:2000,
        end:2499,
        price:71,
        mr:50,
        id:"21",
        name:"S Kluge"

    },
    {
        start:2500,
        end:2999,
        price:61,
        mr:50,
        id:"21",
        name:"S Kluge"

    },
    {
        start:3000,
        end:3999,
        price:56,
        mr:50,
        id:"21",
        name:"S Kluge"

    },
    {
        start:4000,
        end:4999,
        price:51,
        mr:50,
        id:"21",
        name:"S Kluge"

    },
    {
        start:5000,
        end:5999,
        price:46,
        mr:50,
        id:"21",
        name:"S Kluge"

    },
    {
        start:6000,
        end:7499,
        price:45,
        mr:50,
        id:"21",
        name:"S Kluge"

    },
    {
        start:7500,
        end:9999,
        price:43,
        mr:50,
        id:"21",
        name:"S Kluge"

    },
    {
        start:10000,
        end:12499,
        price:41,
        mr:50,
        id:"21",
        name:"S Kluge"

    },
    {
        start:12500,
        end:14999,
        price:40,
        mr:50,
        id:"21",
        name:"S Kluge"

    },
    {
        start:15000,
        end:17499,
        price:39,
        mr:50,
        id:"21",
        name:"S Kluge"

    },
    {
        start:17500,
        end:19999,
        price:38,
        mr:50,
        id:"21",
        name:"S Kluge"

    },
    {
        start:20000,
        end:22499,
        price:37,
        mr:50,
        id:"21",
        name:"S Kluge"

    },
    {
        start:22500,
        end:24999,
        price:37,
        mr:50,
        id:"21",
        name:"S Kluge"

    },
    {
        start:25000,
        end:29999,
        price:36,
        mr:50,
        id:"21",
        name:"S Kluge"

    },
    {
        start:30000,
        end:39999,
        price:36,
        mr:50,
        id:"21",
        name:"S Kluge"

    },
    {
        start:40000,
        end:49999,
        price:35,
        mr:50,
        id:"4",
         name:"S Kluge"
    },
    {
        start:50000,
        end:59999,
        price:34,
        mr:50,
        id:"21",
        name:"S Kluge"

    },
    {
        start:60000,
        end:74999,
        price:34,
        mr:50,
        id:"21",
        name:"S Kluge"

    },
    {
        start:75000,
        end:99999,
        price:34,
        mr:50,
        id:"21",
        name:"S Kluge"

    },
    {
        start:100000,
        end:1000000000,
        price:33,
        mr:50,
        id:"21",
        name:"S Kluge"

    }
]

export const sara = [
    {
        start:1,
        end:50,
        price:18,
        mr:50,
        id:"13",
        name:"Sara"

    },
    {
        start:500,
        end:999,
        price:61,
        mr:50,
        id:"13",
        name:"Sara"
    },
    {
        start:1000,
        end:1999,
        price:60,
        mr:60,
        id:"13",
        name:"Sara"

    },
    {
        start:2000,
        end:2499,
        price:71,
        mr:50,
        id:"13",
        name:"Sara"

    },
    {
        start:2500,
        end:2999,
        price:53,
        mr:50,
        id:"13",
        name:"Sara"

    },
    {
        start:3000,
        end:3999,
        price:56,
        mr:50,
        id:"13",
        name:"Sara"

    },
    {
        start:4000,
        end:4999,
        price:51,
        mr:50,
        id:"13",
        name:"Sara"

    },
    {
        start:5000,
        end:5999,
        price:50,
        mr:50,
        id:"13",
        name:"Sara"

    },
    {
        start:6000,
        end:7499,
        price:45,
        mr:50,
        id:"13",
        name:"Sara"

    },
    {
        start:7500,
        end:9999,
        price:43,
        mr:50,
        id:"13",
        name:"Sara"

    },
    {
        start:10000,
        end:12499,
        price:41,
        mr:50,
        id:"13",
        name:"Sara"

    },
    {
        start:12500,
        end:14999,
        price:40,
        mr:50,
        id:"13",
        name:"Sara"

    },
    {
        start:15000,
        end:17499,
        price:39,
        mr:50,
        id:"13",
        name:"Sara"

    },
    {
        start:17500,
        end:19999,
        price:38,
        mr:50,
        id:"13",
        name:"Sara"

    },
    {
        start:20000,
        end:22499,
        price:37,
        mr:50,
        id:"13",
        name:"Sara"

    },
    {
        start:22500,
        end:24999,
        price:37,
        mr:50,
        id:"13",
        name:"Sara"

    },
    {
        start:25000,
        end:29999,
        price:36,
        mr:50,
        id:"13",
        name:"Sara"

    },
    {
        start:30000,
        end:39999,
        price:36,
        mr:50,
        id:"13",
        name:"Sara"

    },
    {
        start:40000,
        end:49999,
        price:35,
        mr:50,
        id:"4",
         name:"S Kluge"
    },
    {
        start:50000,
        end:59999,
        price:34,
        mr:50,
        id:"13",
        name:"Sara"

    },
    {
        start:60000,
        end:74999,
        price:34,
        mr:50,
        id:"13",
        name:"Sara"

    },
    {
        start:75000,
        end:99999,
        price:34,
        mr:50,
        id:"13",
        name:"Sara"

    },
    {
        start:100000,
        end:1000000000,
        price:33,
        mr:50,
        id:"13",
        name:"Sara"

    }
]
//////////////////////////////////////////////////////////Folding




export const foldoneupcurve = [
    {
        start:1,
        end:499,
        price:50,
        mr:50
    },
    {
        start:500,
        end:999,
        price:50,
        mr:50
    },
    {
        start:1000,
        end:1999,
        price:50,
        mr:50
    },
    {
        start:2000,
        end:2499,
        price:46,
        mr:50
    },
    {
        start:2500,
        end:2999,
        price:44,
        mr:50
    },
    {
        start:3000,
        end:3999,
        price:41,
        mr:50
    },
    {
        start:4000,
        end:4999,
        price:39,
        mr:50
    },
    {
        start:5000,
        end:5999,
        price:36,
        mr:50
    },
    {
        start:6000,
        end:7499,
        price:35,
        mr:50
    },
    {
        start:7500,
        end:9999,
        price:34,
        mr:50
    },
    {
        start:10000,
        end:12499,
        price:31,
        mr:50
    },
    {
        start:12500,
        end:14999,
        price:29,
        mr:50
    },
    {
        start:15000,
        end:17499,
        price:29,
        mr:50
    },
    {
        start:17500,
        end:19999,
        price:28,
        mr:50
    },
    {
        start:20000,
        end:22499,
        price:27,
        mr:50
    },
    {
        start:22500,
        end:24999,
        price:27,
        mr:50
    },
    {
        start:25000,
        end:29999,
        price:26,
        mr:50
    },
    {
        start:30000,
        end:39999,
        price:25,
        mr:50
    },
    {
        start:40000,
        end:49999,
        price:25,
        mr:50
    },
    {
        start:50000,
        end:59999,
        price:24,
        mr:50
    },
    {
        start:60000,
        end:74999,
        price:24,
        mr:50
    },
    {
        start:75000,
        end:99999,
        price:23,
        mr:50
    },
    {
        start:100000,
        end:1000000000,
        price:21,
        mr:50
    }
]


export const foldtwoupcurve = [
    {
        start:1,
        end:499,
        price:66,
        mr:55
    },
    {
        start:500,
        end:999,
        price:66,
        mr:55
    },
    {
        start:1000,
        end:1999,
        price:66,
        mr:55
    },
    {
        start:2000,
        end:2499,
        price:64,
        mr:55
    },
    {
        start:2500,
        end:2999,
        price:61,
        mr:55
    },
    {
        start:3000,
        end:3999,
        price:56,
        mr:55
    },
    {
        start:4000,
        end:4999,
        price:51,
        mr:55
    },
    {
        start:5000,
        end:5999,
        price:46,
        mr:55
    },
    {
        start:6000,
        end:7499,
        price:44,
        mr:55
    },
    {
        start:7500,
        end:9999,
        price:41,
        mr:55
    },
    {
        start:10000,
        end:12499,
        price:36,
        mr:55
    },
    {
        start:12500,
        end:14999,
        price:35,
        mr:55
    },
    {
        start:15000,
        end:17499,
        price:33,
        mr:55
    },
    {
        start:17500,
        end:19999,
        price:32,
        mr:55
    },
    {
        start:20000,
        end:22499,
        price:31,
        mr:55
    },
    {
        start:22500,
        end:24999,
        price:38,
        mr:55
    },
    {
        start:25000,
        end:29999,
        price:25,
        mr:55
    },
    {
        start:30000,
        end:39999,
        price:24,
        mr:55
    },
    {
        start:40000,
        end:49999,
        price:23,
        mr:55
    },
    {
        start:50000,
        end:59999,
        price:22,
        mr:55
    },
    {
        start:60000,
        end:74999,
        price:22,
        mr:55
    },
    {
        start:75000,
        end:99999,
        price:21,
        mr:55
    },
    {
        start:100000,
        end:1000000000,
        price:20,
        mr:55
    }
]


export const papers= [
    {
        paperid:1,
        name:"60# Offset",
        thickness:.003
    },
    {
        paperid:2,
        name:"70# Offset",
        thickness:.004
    }
]



export const platecost = [
    {
        id:1,
        squareinch:4,
        cost:57
    },
    {
        id:2,
        squareinch:4,
        cost:57
        },
        {
            id:3,
            squareinch:4,
            cost:57
        },
        {
            id:4,
            squareinch:4,
            cost:57
        },
        {
            id:5,
            squareinch:5,
            cost:63
        },
        {
            id:6,
            squareinch:6,
            cost:68
        },
        {
            id:7,
            squareinch:7,
            cost:71
        },
        {
            id:8,
            squareinch:8,
            cost:74
        },
        {
            id:9,
            squareinch:9,
            cost:76
        },
        {
            id:10,
            squareinch:10,
            cost:77
        },
        {
            id:11,
            squareinch:11,
            cost:78
        },
        {
            id:12,
            squareinch:12,
            cost:80
            },
            {
                id:13,
                squareinch:13,
                cost:81
            },
            {
                id:14,
                squareinch:14,
                cost:82
            },
            {
                id:15,
                squareinch:15,
                cost:83
            },
            {
                id:16,
                squareinch:16,
                cost:84
            },
            {
                id:17,
                squareinch:17,
                cost:85
            },
            {
                id:18,
                squareinch:18,
                cost:87
            },
            {
                id:19,
                squareinch:19,
                cost:89
            },
            {
                id:20,
                squareinch:20,
                cost:91
            },    {
                id:21,
                squareinch:21,
                cost:92
            },
            {
                id:22,
                squareinch:22,
                cost:94
                },
                {
                    id:23,
                    squareinch:23,
                    cost:96
                },
                {
                    id:24,
                    squareinch:24,
                    cost:98
                },
                {
                    id:25,
                    squareinch:25,
                    cost:99
                },
                {
                    id:26,
                    squareinch:26,
                    cost:101
                },
                {
                    id:27,
                    squareinch:27,
                    cost:102
                },
                {
                    id:28,
                    squareinch:28,
                    cost:103
                },
                {
                    id:29,
                    squareinch:29,
                    cost:105
                },
                {
                    id:30,
                    squareinch:30,
                    cost:106
                },
                {
                    id:31,
                    squareinch:31,
                    cost:108
                },
                {
                    id:32,
                    squareinch:32,
                    cost:109
                    },
                    {
                        id:33,
                        squareinch:33,
                        cost:110
                    },
                    {
                        id:34,
                        squareinch:34,
                        cost:111
                    },
                    {
                        id:35,
                        squareinch:35,
                        cost:113
                    },
                    {
                        id:36,
                        squareinch:36,
                        cost:114
                    },
                    {
                        id:37,
                        squareinch:37,
                        cost:115
                    },
                    {
                        id:38,
                        squareinch:38,
                        cost:116
                    },
                    {
                        id:39,
                        squareinch:39,
                        cost:118
                    },
                    {
                        id:40,
                        squareinch:40,
                        cost:119
                    },
]

    

export const diecutpresses = [
    {
        id:"3",
        name:"S Miller",
        minWidth:13,
        minLength:20,
        maxWidth:20,
        maxLength:28,
    },
    {
        id:"4",
        name:"L Miller",
        minWidth:23,
        minLength:35,
        maxWidth:28,
        maxLength:40,
    },
    {
        id:"5",
        name:"S Kluge",
    },{
        id:"6",
        name:"L Kluge",
    },{
        id:"13",
        name:"Sara",
    },{
        id:"8",
        name:"Thompson",
    },{
        id:"9",
        name:"L hand",
    },{
        id:"1",
        name:"Hand Feed",
    }
]


export const pressprices = [
        {
        id:1,
        start:1,
        end:499,
        rate:18
        },
        {
        id:1,
        start:500,
        end:999,
        rate:20
        },
        {
        id:1,
        start:1000,
        end:1999,
        rate:25
        },
        {
        id:1,
        start:2000,
        end:2499,
        rate:23
        },

        {
            id:2,
            start:1,
            end:499,
            rate:21
            },
            {
            id:2,
            start:500,
            end:999,
            rate:25
            },
            {
            id:2,
            start:1000,
            end:1999,
            rate:27
            },
            {
            id:2,
            start:2000,
            end:2499,
            rate:26
            }
    
]


export const foilcostlist = [
    {
        id:1,
        squareinch:1,
        cost:26.81
    },
    {
        id:2,
        squareinch:2,
        cost:26.81
        },
        {
            id:3,
            squareinch:3,
            cost:26.81
        },
        {
            id:4,
            squareinch:4,
            cost:26.81
        },
        {
            id:5,
            squareinch:5,
            cost:29.7
        },
        {
            id:6,
            squareinch:6,
            cost:31.88
        },
        {
            id:7,
            squareinch:7,
            cost:33.53
        },
        {
            id:8,
            squareinch:8,
            cost:34.67
        },
        {
            id:9,
            squareinch:9,
            cost:35.76
        },
        {
            id:10,
            squareinch:10,
            cost:36.38
        },
        {
            id:11,
            squareinch:11,
            cost:36.9
        },
        {
            id:12,
            squareinch:12,
            cost:37.52
            },
            {
                id:13,
                squareinch:13,
                cost:37.98
            },
            {
                id:14,
                squareinch:14,
                cost:38.61
            },
            {
                id:15,
                squareinch:15,
                cost:39.23
            },
            {
                id:16,
                squareinch:16,
                cost:39.74
            },
            {
                id:17,
                squareinch:17,
                cost:40.26
            },
            {
                id:18,
                squareinch:18,
                cost:41.14
            },
            {
                id:19,
                squareinch:19,
                cost:41.97
            },
            {
                id:20,
                squareinch:20,
                cost:42.85
            },    {
                id:21,
                squareinch:21,
                cost:43.57
            },
            {
                id:22,
                squareinch:22,
                cost:44.51
                },
                {
                    id:23,
                    squareinch:23,
                    cost:45.33
                },
                {
                    id:24,
                    squareinch:24,
                    cost:46.21
                },
                {
                    id:25,
                    squareinch:25,
                    cost:46.94
                },
                {
                    id:26,
                    squareinch:26,
                    cost:47.77
                },
                {
                    id:27,
                    squareinch:27,
                    cost:48.23
                },
                {
                    id:28,
                    squareinch:28,
                    cost:48.75
                },
                {
                    id:29,
                    squareinch:29,
                    cost:49.58
                },
                {
                    id:30,
                    squareinch:30,
                    cost:50.2
                },
                {
                    id:31,
                    squareinch:31,
                    cost:50.92
                },
                {
                    id:32,
                    squareinch:32,
                    cost:51.44
                    },
                    {
                        id:33,
                        squareinch:33,
                        cost:52.06
                    },
                    {
                        id:34,
                        squareinch:34,
                        cost:52.54
                    },
                    {
                        id:35,
                        squareinch:35,
                        cost:53.2
                    },
                    {
                        id:36,
                        squareinch:36,
                        cost:53.72
                    },
                    {
                        id:37,
                        squareinch:37,
                        cost:54.39
                    },
                    {
                        id:38,
                        squareinch:38,
                        cost:55.01
                    },
                    {
                        id:39,
                        squareinch:39,
                        cost:55.68
                    },
                    {
                        id:40,
                        squareinch:40,
                        cost:56.2
                    },
]



export const wireo = [
    {
        decimal:0.1875,
        fraction:"3/16",
        mm:4.763,
        pitch:3,
        cost:1.00,
        roll:5.00,
        boxquantity:100,
        boxcost:24.00,
         spoolcost:124.00,
        loopsperroll:30000
        

     },
    {
        decimal:0.25,
        fraction:"1/4",
        mm:6.35,
        pitch:3,
        cost:1.00,
        roll:5.00,
        boxquantity:100,
        boxcost:53.00,
         spoolcost:164.00,
        loopsperroll:90000
    },
    {
        decimal:0.3125,
        fraction:"5/16",
        mm:7.938,
        pitch:3,
        cost:1.00,
        roll:5.00,
        boxquantity:100,
        boxcost:43.00,
         spoolcost:164.00,
        loopsperroll:66000
    },
    {
        decimal:0.375,
        fraction:"3/8",
        mm:9.525,
        pitch:3,
        cost:1.00,
        roll:5.00,
        boxquantity:100,
        boxcost:53.00,
         spoolcost:164.00,
        loopsperroll:44000
    },
    {
        decimal:0.4375,
        fraction:"7/16",
        mm:11.113,
        pitch:3,
        cost:1.00,
        roll:5.00,
        boxquantity:100,
        boxcost:53.00,
         spoolcost:164.00,
        loopsperroll:32000
    },
    {
        decimal:0.50,
        fraction:"1/2",
        mm:12.7,
        pitch:3,
        cost:1.00,
        roll:5.00,
        boxquantity:100,
        boxcost:53.00,
         spoolcost:164.00,
        loopsperroll:25500
    },
    {
        decimal:0.5625,
        fraction:"9/16",
        mm:14.288,
        pitch:3,
        cost:1.00,
        roll:5.00,
        boxquantity:100,
        boxcost:43.00,
         spoolcost:164.00,
        loopsperroll:22000
    },
    {
        decimal:0.625,
        fraction:"5/8",
        mm:15.875,
        pitch:2,
        cost:1.00,
        roll:5.00,
        boxquantity:100,
        boxcost:53.00,
        spoolcost:43.28,
        loopsperroll:10500
    },
    {
        decimal:0.75,
        fraction:"3/4",
        mm:19.05,
        pitch:2,
        cost:1.00,
        roll:5.00,
        boxquantity:100,
        boxcost:53.00,
         spoolcost:164.00,
        loopsperroll:8000
    },
    {
        decimal:0.875,
        fraction:"7/8",
        mm:22.225,
        pitch:2,
        cost:1.00,
        roll:5.00,
        boxquantity:100,
        boxcost:53.00,
         spoolcost:164.00,
        loopsperroll:6100
    },
    {
        decimal:1,
        fraction:"1",
        mm:25.4,
        pitch:2,
        cost:1.00,
        roll:5.00,
        boxquantity:100,
        boxcost:53.00,
         spoolcost:164.00,
        loopsperroll:30000
    },
    {
        decimal:5,
        fraction:"1 1/4",
        mm:25.5,
        pitch:2,
        cost:1.00,
        roll:5.00,
        boxquantity:100,
        boxcost:53.00,
         spoolcost:164.00,
        loopsperroll:2900
    }
  

]


export const plastikol = [
    {
        mm:6,
        decimal:.25,
        fraction:1/4,
        length:12,
        pitch:4,
        boxquantity:100,
        boxcost:6.99
     },
    {
        mm:7,
        decimal:.281,
        fraction:9/32,
        length:12,
        pitch:4,
        boxquantity:100,
        boxcost:7.39
        
    },
    {
        mm:8,
        decimal:.313,
        fraction:5/16,
        length:12,
        pitch:4,
        boxquantity:100,
        boxcost:7.95
    
    },
    {
        decimal:0.375,
        fraction:"3/8",
        mm:9.525,
        pitch:3,
        cost:1.00,
        roll:5.00,
        boxquantity:100,
        boxcost:53.00,
    
    },
    {
        decimal:0.4375,
        fraction:"7/16",
        mm:11.113,
        pitch:3,
        cost:1.00,
        roll:5.00,
        boxquantity:100,
        boxcost:53.00,
        
    },
    {
        decimal:0.50,
        fraction:"1/2",
        mm:12.7,
        pitch:3,
        cost:1.00,
        roll:5.00,
        boxquantity:100,
        boxcost:53.00,
     
    },
    {
        decimal:0.5625,
        fraction:"9/16",
        mm:14.288,
        pitch:3,
        cost:1.00,
        roll:5.00,
        boxquantity:100,
        boxcost:43.00,
     
    },
    {
        decimal:0.625,
        fraction:"5/8",
        mm:15.875,
        pitch:2,
        cost:1.00,
        roll:5.00,
        boxquantity:100,
        boxcost:53.00,
   
    },
    {
        decimal:0.75,
        fraction:"3/4",
        mm:19.05,
        pitch:2,
        cost:1.00,
        roll:5.00,
        boxquantity:100,
        boxcost:53.00,
     
    },
    {
        decimal:0.875,
        fraction:"7/8",
        mm:22.225,
        pitch:2,
        cost:1.00,
        roll:5.00,
        boxquantity:100,
        boxcost:53.00,
      
    },
    {
        decimal:1,
        fraction:"1",
        mm:25.4,
        pitch:2,
        cost:1.00,
        roll:5.00,
        boxquantity:100,
        boxcost:53.00,
     
    },
    {
        decimal:1.25,
        fraction:"1 1/4",
        mm:25.5,
        pitch:2,
        cost:1.00,
        roll:5.00,
        boxquantity:100,
        boxcost:53.00,
     
    }
]

export const paperthickness = [
    {
        name:"Coated Text/Book 60 lb",
        thickness:0.003
    },
    {
        name:"Coated Text/Book 70 lb",
        thickness:0.0035
    },
    {
        name:"Coated Text/Book 80 lb",
        thickness:0.004
    },
    {
        name:"Coated Text/Book 100 lb",
        thickness:0.005
    },
    {
        name:"Coated Text/Book 120 lb",
        thickness:0.006
    },
    {
        name:"Coated Cover 65 lb",
        thickness:0.0065
    },
    {
        name:"Coated Cover 80 lb",
        thickness:0.008
    },
    {
        name:"Coated Cover 100 lb",
        thickness:0.01
    },
    {
        name:"Uncoated Cover 50 lb",
        thickness:0.0065
    },
    {
        name:"Uncoated Cover 50 lb",
        thickness:0.0065
    },
    {
        name:"Uncoated Cover 65 lb",
        thickness:0.0095
    },
    {
        name:"Uncoated Cover 80 lb",
        thickness:0.0115
    },
    {
        name:"Dull Text/Book 70 lb",
        thickness:0.004
    },
    {
        name:"Dull Text/Book 80 lb",
        thickness:0.0045
    },
    {
        name:"Dull Text/Book 100 lb",
        thickness:0.0055
    },
    {
        name:"C1S / C2S Cover 8 pt",
        thickness:0.008
    },
    {
        name:"C1S / C2S Cover 9 pt",
        thickness:0.009
    },
    {
        name:"C1S / C2S Cover 10 pt",
        thickness:0.010
    },
    {
        name:"C1S / C2S Cover 12 pt",
        thickness:0.012
    },
    {
        name:"Offset 50 lb",
        thickness:0.004
    },
    {
        name:"Offset 60 lb",
        thickness:0.0045
    },
    {
        name:"Offset 70 lb",
        thickness:0.0055
    },
    {
        name:"Offset 80 lb",
        thickness:0.006
    },
    {
        name:"Offset 100 lb",
        thickness:0.0075
    },
    {
        name:"Offset 120 lb",
        thickness:0.009
    },
    {
        name:"Offset 150 lb",
        thickness:0.011
    },
    {
        name:"Index 90 lb",
        thickness:0.007
    },
    {
        name:"Index 110 lb",
        thickness:0.00825
    },
    {
        name:"Index 140 lb",
        thickness:0.01075
    },
    {
        name:"Index 170 lb",
        thickness:0.0135
    },
    {
        name:"Index 220 lb",
        thickness:0.017
    },
    {
        name:"Tag 125 lb",
        thickness:0.009
    },
    {
        name:"Text 60 lb",
        thickness:0.0045
    },
    {
        name:"Text 70 lb",
        thickness:0.0065
    },
    {
        name:"Text 80 lb",
        thickness:0.07
    },
    {
        name:"Bond 13 lb",
        thickness:0.03
    },
    {
        name:"Bond 16 lb",
        thickness:0.035
    },
    {
        name:"Bond 20 lb",
        thickness:0.004
    },
    {
        name:"Bond 24 lb",
        thickness:0.00475
    },
]

export const allboxes = [
{
    boxsize:"9x12x12",
    boxid:1,
    boxcost:1.50,
    boxholds:1296
},
{
    boxsize:"12x12x12",
    boxid:2,
    boxcost:1.50,
    boxholds:1728
},
{ boxsize:"9x10x12",
    boxid:3,
    boxcost:1.50,
    boxholds:1080}
]


export const standardonepocket = [
    {
        quantity:500,
        price:160,
        per:.32
    },
    {
        quantity:1000,
        price:204,
        per:.204
    },
    {
        quantity:1500,
        price:274,
        per:.182
    },
    {
        quantity:2000,
        price:319,
        per:.159
    },
    {
        quantity:2500,
        price:362,
        per:.144
    },
    {
        quantity:3000,
        price:397,
        per:.132
    },
    {
        quantity:3500,
        price:452,
        per:.129
    },
    {
        quantity:4000,
        price:487,
        per:.121
    },

]

export const standardtwopocket = [
    {
        quantity:500,
        price:182,
        per:.364
    },
    {
        quantity:1000,
        price:236,
        per:.236
    },
    {
        quantity:1500,
        price:315,
        per:.210
    },
    {
        quantity:2000,
        price:375,
        per:.1875
    },
    {
        quantity:2500,
        price:425,
        per:.170
    },
    {
        quantity:3000,
        price:464,
        per:.154
    },
    {
        quantity:3500,
        price:529,
        per:.151
    },
    {
        quantity:4000,
        price:558,
        per:.1395
    },

]